import requestService from './requestService';
import cookieService from './cookieService';
import axios from 'axios';

export class ComponentService {
  static async getComponents(lessonId, languageId) {
    const path = '/rest/component/components?lessonId=' +
      lessonId +
      '&languageId=' +
      languageId;

    return requestService.makeGetRequest(path);
  }

  static async getComponentContent(componentId, contentType, originalLanguageId, translationLanguageId) {
    let path = '';

    switch (contentType) {
      case 'photolesson':
        path = process.env.VUE_APP_DOMAIN +
          '/rest/photolesson/photolessons?photolessonId=' +
          componentId +
          '&originalLanguageId=' +
          originalLanguageId +
          '&translationLanguageId=' +
          translationLanguageId;
        break;
      case 'exercise':
        path = process.env.VUE_APP_DOMAIN +
          '/rest/exercise/exercises?exerciseId=' +
          componentId +
          '&originalLanguageId=' +
          originalLanguageId +
          '&translationLanguageId=' +
          translationLanguageId;
        break;
      case 'movie':
        path = process.env.VUE_APP_DOMAIN +
          '/rest/movie/movies?movieId=' +
          componentId +
          '&originalLanguageId=' +
          originalLanguageId +
          '&translationLanguageId=' +
          translationLanguageId;
        break;
    }

    return requestService.makeGetRequest(path);
  }

  static async setComponentProgress(componentId, componentProgress) {
    const token = cookieService.getCookie('token');

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/component/component-progress',
      {
        componentId: componentId,
        progress: componentProgress
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      }
    );
  }

  static async getComponentProgress(componentId) {
    const path = '/rest/component/component-progress?componentId=' + componentId;

    return requestService.makeGetRequest(path);
  }

  static async getUnitsProgress() {
    const path = '/rest/component/unit-progress';

    return requestService.makeGetRequest(path);
  }

  static async getSubunitProgress(subunitId) {
    const path = '/rest/component/subunit-progress?categoryId=' + subunitId;

    return requestService.makeGetRequest(path);
  }

  static async setTaskProgress(componentId, taskId) {
    const token = cookieService.getCookie('token');

    return await axios.post(
      process.env.VUE_APP_DOMAIN + '/rest/component/task-progress',
      {
        componentId: componentId,
        taskId: taskId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      }
    );
  }
}

const componentService = ComponentService;
export default componentService;
