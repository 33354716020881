import requestService from './requestService';

export class CategoryService {
  static getUnits(courseId, languageId, isRootCategory = null, parentCategoryId = null) {
    let path = process.env.VUE_APP_DOMAIN +
      '/rest/category/categories?courseId=' +
      courseId +
      '&languageId=' +
      languageId;

    if (isRootCategory) {
      path = path + '&isRootCategory=' + isRootCategory;
    }

    if (parentCategoryId) {
      path = path + '&parentCategoryId=' + parentCategoryId;
    }

    return requestService.makeGetRequest(path);
  }

  static async getCategoryData(categoryId) {
    const path = process.env.VUE_APP_DOMAIN + '/rest/category/category/id/' + categoryId;

    return requestService.makeGetRequest(path);
  }
}

const categoryService = CategoryService;
export default categoryService;
